.work__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.work__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
  transition: 0.4s;
}

/* .work__card:hover {
  transform: translateY(-1.5rem);
} */

.work__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.work__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: var(--link-color, #0000f8);
}

.work__button-icon {
  font-size: 1.5rem;
  transition: 0.4s;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .work__container {
    gap: 1.5rem;
    width: 98vw;
  }

  .work__card {
    padding: 1rem;
  }

  .work__img {
    margin-bottom: 0.75rem;
    width: 230px;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: max-content;
  }

  .work__card:hover {
    transform: none;
  }
}

@media screen and (max-width: 576px) {
  .work__container {
    grid-template-columns: 1fr;
  }
  .work__img {
    width: 100%;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
}
